import { useState } from 'react';
import './App.css';
import JSZip from 'jszip';

function App() {

  const [results, setResults] = useState({});

  const pageUrls = {
    content_item: 'tnetnoc/',
    landing_page: 'lp/',
    focused_type: 'pages/',
    medications: 'medications/',
    pricing_page: 'pricing',
    medication: 'medication',
    home_page: '',
    about_page: 'about-us',
    how_it_works_page: 'how-it-works',
    faqs_page: 'faqs',
    reviews_page: 'reviews',
  };

  const submitHandler = (files) => {
    const result = {};
    const promises = [];

    JSZip.loadAsync(files)
    .then((zip) => {
      zip.forEach(function (relativePath, zipEntry) {  
        promises.push(
          zipEntry.async("string").then(function (content) { 
          const data = JSON.parse(content.toString('utf8'));
          const {
            uid, type, body,
          } = data;

          const formatItem = {
            search: `https://calibrate.prismic.io/documents/working?k=${type}&s=${uid}&l=en-us`,
            link:`https://www.joincalibrate.com/${pageUrls[type]}${uid}`,
          };

          if (body?.length) {
            body.forEach((el) => {
              if (el?.key) {
                const slice = el.key.replace(/[$].*/i, '');
                if (slice in result) {
                  !result[slice].includes(formatItem) && result[slice].push(formatItem);
                } else {
                  result[slice] = [formatItem];
                }
              }
            });
          }
         }));
      });

      Promise.all(promises).then(() => {

        for (const slice in result) {
          result[slice].sort();
        }

        setResults(prevState => ({
          ...prevState,
          ...result,
          }));
        });
    })
  }

  return (
    <div className="App">
      <header className="instructions">
        <p>Export zip archive from <a target='_blank' href="https://calibrate.prismic.io/settings/import/" rel="noreferrer">https://calibrate.prismic.io/settings/import/</a></p>
        <input id="zip" name="zip" type="file" onChange={(e) => submitHandler(e.target.files[0])}/>
      </header>

      <main>

      <div className="nav">
        <ul>
          {Object.keys(results).map((slice, i) => (
            <li key={i}>
              <a href={`#${slice}`}>{slice.replace(/_/g, " ")}</a>
              <span className={`${results[slice].length <= 5 && 'danger'}`}>{results[slice].length}</span>
            </li>
          ))}
        </ul>
      </div>

      <div className="result">
        {Object.keys(results).map((slice, index) => (
          <section key={index} id={slice}>
            <header>
              <h2>{slice.replace(/_/g, " ")}</h2>
              <h3>Total: {results[slice].length}</h3>
            </header>
            <ol>
              {results[slice].map((item, index) => (
                <li key={index}>
                  <a href={item.link} target="_blank" rel="noreferrer">{item.link}</a>
                  <a href={item.search} target="_blank" rel="noreferrer">🔍</a>
                </li>
              ))}
            </ol>
        </section>
        ))}
      </div>
      </main>
    </div>
  );
}

export default App;
